import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { HighlightedText } from 'src/components/atoms/HighlightedText';
import { Grid } from 'src/components/molecules/Grid';
import { Teaser } from 'src/components/molecules/Teaser';
import { FixedImg } from 'src/embeds/image/image-dato';
import { FlowAlt } from 'src/system';
import styled from 'styled-components';
const query = graphql`
  query ShowcaseTeaserData {
    allDatoCmsShowcase(sort: { order: ASC, fields: position }) {
      group(field: category___slug, limit: 1) {
        nodes {
          id
          heading
          slug
          linkedAuthor {
            ... on DatoCmsCustomer {
              name
              logo {
                ...GatsbyDatoCmsImageCustom
              }
            }
          }
          image {
            ...GatsbyDatoCmsImageCustom
          }
          category {
            title
          }
        }
      }
    }
  }
`;
const ShowcaseGrid = styled(Grid)`
  margin: 3.5rem auto;
`;
export const ShowcaseTeaser = () => {
  const data = useStaticQuery(query);
  const groups = data.allDatoCmsShowcase?.group;
  return (
    <ShowcaseGrid columns="repeat(4, 1fr)">
      {groups.map((group) => {
        const showcase = group.nodes[0];
        if (!showcase) return null;
        return (
          <Teaser
            to={`/showcase/${showcase.slug}`}
            header={
              !!showcase.image && ( // @ts-ignore
                <FixedImg height={230} data={showcase.image} />
              )
            }
            subtitle={showcase.category.title}
            title={showcase.heading}
            footer={
              showcase.community ? (
                <HighlightedText bold>Rasa community project</HighlightedText>
              ) : (
                showcase.linkedAuthor?.logo && (
                  <FlowAlt gap={8}>
                    {/* @ts-ignore */}
                    <FixedImg
                      constrain
                      width={Math.round(
                        (showcase.linkedAuthor.logo.width / showcase.linkedAuthor.logo.height) * 30,
                      )}
                      height={30}
                      data={showcase.linkedAuthor.logo}
                    />
                  </FlowAlt>
                )
              )
            }
            key={showcase.slug}
          />
        );
      })}
    </ShowcaseGrid>
  );
};
