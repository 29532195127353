import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section, SectionHeader } from 'src/components/molecules/Section';
import { ShowcaseTeaser } from 'src/containers/ShowcaseTeaser';
import { Button } from 'src/components/atoms/Button';

export default function Drawer({ heading, content, contentNode, ctas, customData }) {
  customData = JSON.parse(customData);
  return (
    <Section {...customData?.flags}>
      <MDXProvider components={MDXComponents}>
        <SectionHeader>
          <h2>{heading}</h2>
          {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        </SectionHeader>
      </MDXProvider>
      <ShowcaseTeaser />
      {ctas.map((cta, i) => (
        <Button key={i} to={cta.url} aria-label={cta.a11yText || cta.text}>
          {cta.text}
        </Button>
      ))}
    </Section>
  );
}
